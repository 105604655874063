<template>
    <div>
        <div class="product-box mainwidth">
            <div class="product-con">
                <div class="item-left">
                    <img v-if="productDetail.LOGO_ADDR != ''" :src="imgApi + productDetail.LOGO_ADDR">
                    <img v-else src="../assets/images/prodct-con01.png">
                </div>
                <a-divider type="vertical" style="height:auto;margin:0 15px" />
                <div class="item-right">
                    <div class="item-right-top">
                        <p>{{productDetail.PRODUCT_NAME}}</p>
                    </div>
<!--                    <div class="item-right-bot">-->
<!--                        <p>服务项目：<span class="sp-special">{{productDetail.SERVICE_COLUMN}}</span></p>-->
<!--                        <p>利率区间：<span class="sp-special">{{productDetail.INTEREST_RATE_START}}</span></p>-->
<!--                        <p>额度范围：<span class="sp-special">{{productDetail.MONEY_START}}万元以下</span></p>-->
<!--                        <p>发行机构：<span>{{productDetail.BANK_NAME}}</span></p>-->
<!--                        <p>担保方式：<span>{{productDetail.GUARANTEE_METHOD}}</span></p>-->
<!--                        <p>期限范围：<span>{{productDetail.BETWEEN_MONTHS_S}}个月以下</span></p>-->
<!--                        <p>累计申请：<span>{{productDetail.LINK_COUNT}}次</span></p>-->
<!--                        <p>发布时间：<span>{{productDetail.RELEAS_TIME}}</span></p>-->
<!--                        <p>适用区域：<span>{{productDetail.APPLICABLE_REGION}}</span></p>-->
<!--                    </div>-->
                    <div class="item-right-bot">
                        <div class="item-right-bot-list">
                            <p>服务项目：<span class="sp-special">{{productDetail.SERVICE_COLUMN}}</span></p>
                            <p>额度范围：<span class="sp-special">{{productDetail.MONEY_START}}万元</span></p>
                            <p>担保方式：<span class="sp-special">{{productDetail.GUARANTEE_METHOD}}</span></p>
                            <p>期限范围：<span class="sp-special">{{productDetail.BETWEEN_MONTHS_S}}个月</span></p>
                        </div>

                        <div class="item-right-bot-list">
                            <p style="width: 440px">发行机构：<span>{{productDetail.BANK_NAME}}</span></p>
                            <p>累计申请：<span>{{productDetail.LINK_COUNT}}次</span></p>
                        </div>
                        <p style="width: 100%">利率区间：<span>{{productDetail.INTEREST_RATE_START}}</span></p>
                    </div>
                </div>
            </div>
            <ul class="product-content">
                <li>
                    <p class="title">适用客户</p>
                    <p class="content">{{productDetail.APPLICABLE_AGENT}}</p>
                </li>
                <li>
                    <p class="title">产品特点</p>
                    <p class="content">{{productDetail.PRODUCTS_CHAR}}</p>
                </li>
                <li>
                    <p class="title">产品简介</p>
                    <p class="content">{{productDetail.PRODUCTS_REMARK}}</p>
                </li>
                <li>
                    <p class="title">申请条件</p>
                    <p class="content">{{productDetail.APPLY_CONDISTION}}</p>
                </li>
                <li>
                    <p class="title">提交材料</p>
                    <p class="content">{{productDetail.MAKINGS}}</p>
                </li>
                <li>
                    <p class="title">业务承办机构</p>
                    <p class="content">{{productDetail.HANDINST_BANKNAME}}</p>
                </li>
                <div style="margin-top: 20px;text-align: center;" v-if="isLogin && loginType === 'qiye'">
                    <a-button style="padding: 0 40px;background: #457DFE;color: #ffffff;" @click="onApply">立即申请</a-button>
                </div>
            </ul>
        </div>
        <a-modal
            :visible="bankListShow"
            title="选择业务承办机构"
            @cancel="bankListShow = false; searchBankVal = ''"
            @ok="onSubmit"
            ok-text="确定"
            cancel-text="取消"
        >
            <a-input-search :value="searchBankVal" enter-button placeholder="请输入机构名称" @search="getBankList" style="margin-bottom: 20px;"/>
            <div style="height: 200px;overflow-y: scroll;">
                <p v-for="(bItem, bi) in bankLists" :key="bi" @change="onPickBank(bItem)">
                    <a-radio-group v-model:value="banliName">
                        <a-radio v-model:value="bItem.DEPARTMENT_ID">{{bItem.NAME}}</a-radio>
                    </a-radio-group>
                </p>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Base from '@/api/base.js'
export default {
    computed: {
        ...mapGetters(['isLogin', 'loginType', 'productDetail', 'isAuth', 'orgId', 'orgName'])
    },
    data () {
        return {
            id: '',
            imgApi: Base.cs + '/',
            bankListShow: false,
            searchBankVal: '',
            bankLists: [],
            banliName: ''
        }
    },
    methods: {
        onApply () {
            this.getBankList()
            this.bankListShow = true
        },
        getProductDetail () {
            this.$store.dispatch('getProductDetail', { FINANCIALPRODUCTS_ID: this.id, tm: new Date().getTime() })
        },
        onSubmit () {
            if (this.isAuth === 'N') {
                this.$message.info('请先完成企业认证或个体认证后才能申请金融产品')
                return false
            }
            // let BANKCHILD_ID = ''
            // for (let i in this.bankLists) {
            //     if (this.bankLists[i].selected) {
            //         this.bankLists[i].selected = true
            //         BANKCHILD_ID = this.bankLists[i].DEPARTMENT_ID
            //     }
            // }
            this.$store.dispatch('applyProduct', {
                FINAPROD_ID: this.id,
                QUOTA_E: this.productDetail.MONEY_START,
                BANKCHILD_ID: this.BANKCHILD_ID,
                ENTERPRISE_ID: this.orgId,
                LINK_WAY: 'OWN',
                CONFIRM_YN: 'Y',
                tm: new Date().getTime(),
                ENTERPRISE_NAME: this.orgName,
                BANKCHILD_NAME: this.bankName,
                OVER_DATE: this.OVER_DATE,
                RECEIVE_MONEY: this.RECEIVE_MONEY,
                INTEREST_RATE: this.INTEREST_RATE,
            }).then(res => {
                this.bankListShow = false
                this.searchBankVal = ''
                if (res.result === 'success') {
                    this.$message.success('申请成功，请您耐心等待审核！')
                } else {
                    this.$message.error('系统异常')
                }
            })
        },
        onPickBank (item) {
            console.log(item)
            this.bankName = item.NAME
            this.BANKCHILD_ID = item.DEPARTMENT_ID
            for (let i in this.bankLists) {
                if (item.DEPARTMENT_ID == this.bankLists[i].DEPARTMENT_ID) {
                    this.bankLists[i].selected = true
                } else {
                    this.bankLists[i].selected = false
                }
            }
        },
        // 获取银行列表
        getBankList () {
            this.bankLists = []
            console.log(this.productDetail)
            var name = []
            var id = []
            name = this.productDetail.HANDINST_BANKNAME.split(',')
            id = this.productDetail.HANDINST_BANKID.split(',')
            name.map((item,index)=>{
                this.bankLists.push({NAME:item, DEPARTMENT_ID:id[index]})
            })
            // console.log(this.bankLists)
            // this.$store.dispatch('getDepBankList', { showCount: -1, currentPage: 1, BANKNAME: this.searchBankVal }).then(res => {
            //     if (res.result === 'success') {
            //         for (let i in res.varList) {
            //             res.varList[i].selected = false
            //         }
            //         this.bankLists = res.varList
            //     } else {
            //         this.bankLists = []
            //     }
            // })
        }
    },
    mounted () {
        if (this.$route.query.id === '' || this.$route.query.id === null || this.$route.query.id === undefined) {
            this.$message.info('产品ID不存在，可能已经下架！')
            this.$router.go(-1)
            return false
        }
        this.id = this.$route.query.id
        this.getProductDetail()
    }
}
</script>

<style lang="less" scoped>
.product-box{
    .product-con{
        padding: 20px;
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: @color-ff;
        border-radius: 5px;
        box-shadow: 0px 0px 10px #929292;
        display: flex;
        justify-content: space-between;
        border-bottom: @border-base;
        .item-left{
            width: 365px;
            img{
                width: 100%;
                height: 200px;
            }
        }
        .item-right{
            width: 880px;
            .item-right-top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 10px;
                p{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 0;
                    font-size: 30px;
                    font-weight: bold;
                }
                img{
                    width: 150px;
                    height: 40px;
                }
            }
            .item-right-bot{
                border-top: @border-ea;
                padding-top: 10px;
                .item-right-bot-list{
                    display: flex;
                    justify-content: start;
                    flex-wrap: wrap;
                }
                p{
                    width: 220px;
                    padding:5px;
                    margin-bottom: 0;
                    font-size: @font-lg;
                    line-height: 30px;
                    .sp-special{
                        color: @color-blue;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .product-content{
        padding: 20px;
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: @color-ff;
        border-radius: 5px;
        box-shadow: 0px 0px 10px #929292;
        li{
            width: 100%;
            padding: 10px 0;
            border-bottom:@border-ea;
            p{
                margin-bottom: 0;
            }
            .title{
                font-size: @font-lg;
                color: @color-base;
                font-weight: bold;
                line-height: 36px;
            }
            .content{
                line-height: 28px;
                font-size: @font-lg;
                color: @color-base;
                text-align: justify;
            }
        }
    }

}
</style>
